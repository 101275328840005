<template>
	<div class="p-2">
		<template v-for="task in tasks">
			<slot :task="task">
				<div class="border rounded p-1 d-flex flex-row justify-content-between align-items-center mb-2">
					<div class="d-flex flex-column">
						<small>{{task.title}}</small>
						<small>{{task.createdAt | formatDate}}</small>
					</div>
					<div class="d-flex flex-row align-items-center">
						<el-button v-if="task.status==='FINISH'" class="text-success mr-2" type="text" size="mini" icon="el-icon-download" @click="$store.commit('download-manager/exportXlsx', task.id)"></el-button>
						<el-button v-if="task.status==='RUNNING'" class="mr-2" type="text" size="mini" icon="el-icon-loading"></el-button>
						<el-popover
							v-if="task.status==='ERROR'"
						    width="200"
						    trigger="hover"
						    content="该下载任务处于异常状态,点击后将继续下载">
							<el-button slot="reference" type="text" class="text-warning mr-2" size="mini" icon="el-icon-warning-outline" @click="task.status='PENDING'"></el-button>
						</el-popover>
						<el-button type="text" class="text-danger" icon="el-icon-delete" size="mini" @click="removeTask(task.id)"></el-button>
					</div>
				</div>
			</slot>
		</template>
		<template v-if="tasks.length === 0">
			<div class="text-center">
				暂无任务
			</div>
		</template>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters({
				tasks: 'download-manager/tasks'
			})
		},
		methods: {
			removeTask(id) {
				this.$confirm('此操作将移除该任务, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit('download-manager/removeTask', id)
				})
			}
		}
	}
</script>

<style>
</style>
