<template>
	<el-container class="admin-container">
		<el-aside :width="isCollapse|width" style="position:relative;transition:0.2s linear;">
			<div class="text-white text-center admin-header"
				style="position: absolute;left:0;top:0;right:0;height:60px;">
				<div v-if="isCollapse" style="line-height:60px;">
					<img style="width:100%;height:100%;border-right:1px solid #ddd;" :src="require('@/assets/images/WechatIMG603.jpg')" />
					<!-- <span style="font-size:30px;color:white;">
					    <i class="fa-brands fa-adversal"></i>
					</span> -->
				</div>
				<h5 v-else style="line-height:60px;">{{$conf.title}}</h5>
			</div>
			<el-menu
				:default-active="menu"
				@select="openMenu"
				style="position:absolute;top:60px;height:calc(100% - 60px);"
				class="w-100"
				:unique-opened="true"
				:collapse="isCollapse">
				<template v-for="(item, index) in navBar.list">
					<el-menu-item v-if="(item.submenu || []).length === 0" :index="index|numToString" :key="index"
						:data-path="item.pathname">
						<i :class="item.icon"></i>
						<span slot="title">{{item.name}}</span>
					</el-menu-item>
					<el-submenu :index="index|numToString" v-else :key="index">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{item.name}}</span>
						</template>
						<el-menu-item-group>
							<el-menu-item v-for="(sub, subI) in item.submenu" :index="index + '-' +subI" :key="subI"
								:data-path="sub.pathname">
								{{sub.name}}
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
				</template>
			</el-menu>
		</el-aside>
		<!-- 顶部导航 -->
		<el-container style="overflow:hidden;">
			<el-header class="d-flex align-items-center">
				<!-- <el-button :icon="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" type="text"
					@click="isCollapse=!isCollapse" style="font-size:20px;color:black;outline-style:none;"></el-button> -->
				<div class="d-flex align-items-center ml-auto">
					<i class="el-icon-link text-white d-none" style="cursor: pointer;"
						@click.stop="$message('Todo...')"></i>
					<i class="el-icon-question text-white mx-3 d-none" style="cursor: pointer;"
						@click.stop="$message('Todo...')"></i>
					<i class="el-icon-s-data mx-3" :class="{'has-tasks': showBadge}" style="cursor: pointer;"
						@click.stop="$store.commit('download-manager/toggleDrawer')"></i>
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link align-items-center d-flex">
							<span class="px-1" style="line-height: 0;">{{user && user.name}}</span>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="reset-password">重置密码</el-dropdown-item>
							<el-dropdown-item command="logout">登出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</el-header>
			<!-- 主内容 -->
			<el-main class="bg-light" style="position:relative;margin-bottom:-40px;overflow:hidden;">
				<vue-loading :active.sync="loading" color="#409EFF" :is-full-page="isFullPage" loader="bars"
					:z-index="zIndex"></vue-loading>
				<!-- 内容 -->
				<transition name="route" mode="out-in">
				  <router-view></router-view>
				</transition>
				<!-- 全局返回顶部 -->
				<el-backtop target=".el-main" :bottom="100">
					<div class="wms-backtop">UP</div>
				</el-backtop>
			</el-main>
		</el-container>
		<el-dialog title="重置密码" :visible.sync="visible.password">
			<el-form ref="form" :model="form" label-width="80px" :rules="rules">
				<el-form-item label="旧密码" prop="oldPassword">
					<el-input v-model="form.oldPassword" show-password></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPassword">
					<el-input v-model="form.newPassword" show-password></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.password = false">取 消</el-button>
				<el-button type="primary" @click="updatePassword">确 定</el-button>
			</div>
		</el-dialog>
		<el-drawer
		  :visible.sync="drawer"
		  :show-close="false"
		  direction="rtl"
		  size="400px"
		  :before-close="drawerClose">
		  <div slot="title" class="d-flex flex-row align-items-center justify-content-between">
		  	<small class="bold">下载列表</small>
			<div>
				<el-button type="text" class="text-danger" size="mini" @click="confirmAction('download-manager/clearTasks')">清空</el-button>
				<el-button type="text" size="mini" @click="drawerClose">关闭</el-button>
			</div>
		  </div>
		  <download-tasks></download-tasks>
		</el-drawer>
	</el-container>
</template>

<script>
	import VueLoading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
	import common from '@/common/mixins/common.js'
	import {
		mapState,
		mapActions,
		mapGetters
	} from 'vuex'
	import DownloadTasks from '@/components/common/download-tasks.vue'
	export default {
		mixins: [common],
		components: {
			VueLoading,
			DownloadTasks
		},
		provide() {
			return {
				adminLayout: this
			}
		},
		data() {
			return {
				loading: false,
				menu: "",
				isFullPage: false,
				zIndex: 2000,
				isCollapse: true,
				visible: {
					password: false
				},
				form: {
					newPassword: '',
					oldPassword: ''
				},
				rules: {
					newPassword: [{
						validator: (rule, value, callback) => {
							if (!value) {
								callback('新密码不能为空')
							} else {
								if (value.length < 8) {
									callback('密码长度至少8位')
								} else {
									if (value === this.form.oldPassword) {
										callback('新密码不能与旧密码重复')
									} else {
										callback()
									}
								}
							}
						}
					}],
					oldPassword: [{
						validator: (rule, value, callback) => {
							if (!value) {
								callback('旧密码不能为空')
							} else {
								if (value.length < 8) {
									callback('密码长度至少8位')
								} else {
									this.$refs.form.validateField('newPassword')
									callback()
								}
							}
						}
					}]
				}
			}
		},
		filters: {
			width(isCollapse) {
				return isCollapse ? '65px' : '240px'
			}
		},
		mounted() {
			this.activeMenu(this.$route)
			this.getUserSelf()
		},
		computed: {
			...mapState({
				_navBar: state => state.menu.navBar,
				user: state => state.user.user,
				drawer: state => state['download-manager'].drawer,
				showBadge: state => state['download-manager'].showBadge
			}),
			navBar() {
				const {
					superAdmin,
					permissions = []
				} = this.user || {}
				const routes = this.$router.options.routes[1].children
				const list = this._navBar.list.map(v => {
					const {
						icon,
						name,
						submenu = [],
						pathname
					} = v
					if (submenu.length === 0 && pathname) {
						try {
							const {permission} = routes.find(r => r.name === pathname).meta
							if (!superAdmin && permission && !permissions.find(p => p === permission)) {
								return null
							}
						} catch (e) {
							console.error(e)
						}
						return v
					} else if (submenu.length > 0) {
						const _submenu = submenu.map(k => {
							const {
								pathname: sub_pathname
							} = k
							try {
								const {permission} = routes.find(r => r.name === sub_pathname).meta
								if (!superAdmin && permission && !permissions.find(p => p === permission)) {
									return null
								}
							} catch (e) {
								console.error(e)
							}
							return k
						}).filter(k => k !== null)
						return _submenu.length > 0 ? {
							...v,
							submenu: _submenu
						} : null
					}
					return null
				}).filter(v => v !== null)
				return {
					list
				}
			}
		},
		watch: {
			'$route'(_to, _from) {
				if (_to) {
					this.hideLoading()
				}
				this.activeMenu(_to)
			}
		},
		methods: {
			...mapActions([
				'logout',
				'resetPwd',
				'getUserSelf'
			]),
			showLoading(isFullPage = false) {
				this.isFullPage = isFullPage
				this.zIndex = isFullPage ? 3000 : 2000
				this.loading = true
			},
			hideLoading() {
				this.loading = false
			},
			getMenu(indexs) {
				try {
					let menu = this.navBar.list
					indexs.forEach((v, i) => {
						menu = menu[v]
						if (!this.isEmpty(menu.submenu)) {
							menu = menu.submenu
						}
					})
					return menu
				} catch {}
			},
			openMenu(key, keyPath) {
				const keys = [...keyPath]
				this.menu = key
				const path = keys.pop()
				let menu = this.getMenu(path.split('-'))
				if (menu && menu.pathname) {
					this.$router.replace({
						name: menu.pathname,
						params: menu.params || {}
					})
				}
			},
			handleCommand(command) {
				switch (command) {
					case 'logout':
						this.logout()
							.then(() => {
								this.$showSuccess('已成功登出')
								this.$router.replace('/login')
							})
						break
					case 'reset-password':
						this.visible.password = true
						break
					default:
						break
				}
			},
			activeMenu(to) {
				Array.from(document.querySelectorAll('.el-menu-item.is-active')).forEach(el => {
					el.className = 'el-menu-item'
				})
				Array.from(document.querySelectorAll('.el-menu-item')).forEach((el, index) => {
					const path = document.querySelectorAll('.el-menu-item')[index].dataset.path
					// Sub-Path will also active menu bar
					if (to.name.startsWith(path)) {
						el.className = 'el-menu-item is-active'
					}
				})
			},
			updatePassword() {
				this.$refs.form.validate(v => {
					if (v) {
						this.showLoading(true)
						this.resetPwd({
								id: this.user.id,
								body: {
									...this.form
								}
							})
							.then(res => {
								this.hideLoading()
								this.$showSuccess('密码更新成功，请重新登入')
								this.logout()
									.then(() => {
										this.$router.replace('/')
									})
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.hideLoading()
							})
					}
				})
			},
			confirmAction(action) {
				this.$confirm('该操作有一定风险, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.commit(action)
				})
			},
			drawerClose(done) {
				if (typeof done === 'function') {
					this.$confirm('请确认是否要关闭?')
					  .then(_ => {
						this.drawerClose()
					  })
					  .catch(_ => {}); 
				} else {
					this.$store.commit('download-manager/toggleDrawer')
				}
			}
		}
	}
</script>

<style lang="scss">
	.wms-backtop {
		height: 100%;
		width: 100%;
		background-color: #f2f5f6;
		box-shadow: 0 0 6px rgba(0, 0, 0, .12);
		text-align: center;
		line-height: 40px;
		color: #1989fa;
	}

	.admin-container {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		overflow: hidden;
	}

	.admin-header {
		background-color: #545c64;
	}

	#reset-pwd-dialog {
		.el-dialog__body {
			padding-top: 10px;
			padding-bottom: 0px;
		}
	}
	.route-enter,
	.route-leave-to {
		opacity: 0;
		transform: translateY(-30px);
	}
	
	.route-enter-active,
	.route-leave-active {
		transition: all 0.3s ease;
	}
	
	.route-enter-to,
	.route-leave {
		opacity: 1;
		transform: translateY(0);
	}
	.has-tasks:after {
		content: '';
		background: orange;
		width:5px;
		height:5px;
		position: absolute;
		border-radius:50%;
	}
</style>
